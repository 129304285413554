import Vue from 'vue';
import ElementUI from 'element-ui';
import localeEs from 'element-ui/lib/locale/lang/es';
import { formatDistanceToNow, parse } from 'date-fns';
import vueDebounce from 'vue-debounce';
import { es } from 'date-fns/locale';
import './styles/styles.scss';

import './registerServiceWorker';
import { showErrors } from '@/plugins/utils';
import router from './router';
import store from './store';
import auth from './plugins/auth';
import App from './App.vue';
import userTypes from './plugins/userTypes';

Vue.use(ElementUI, {
  locale: localeEs,
});

Vue.use(vueDebounce);

Vue.config.productionTip = false;

require('./plugins/validator');
require('./plugins/axios');

Vue.prototype.$auth = auth;

Vue.mixin({
  filters: {
    typeUserFilter(value) {
      const type = userTypes.find((ut) => ut.value === value);
      return type.label;
    },
    toDateDistanceNowHuman(value) {
      const date = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
      return formatDistanceToNow(date, { locale: es });
    },
    toPrice(value) {
      return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  methods: {
    showErrors,
    onToBack() {
      this.$router.back();
    },
    onShowDeleteAlert(message) {
      return this.$confirm(message, 'Atención', {
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'modal-delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonClass: 'el-button--text mr-3',
      });
    },
    onShowWarningAlert(message) {
      return this.$confirm(message, 'Cuidado', {
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'modal-warning',
        confirmButtonClass: 'el-button--warning',
        cancelButtonClass: 'el-button--text mr-3',
      });
    },
    onFilterProvinces(clearProvincesAndDistricts = false) {
      this.provincesFiltereds = this.provinces.filter(
        (p) => p.department_id === this.form.department_id
      );
      if (clearProvincesAndDistricts) {
        this.form.province_id = '';
        this.form.district_id = '';
      }
    },
    onFilterDistricts(clearDistricts = false) {
      this.districtsFiltereds = this.districts.filter(
        (d) => d.province_id === this.form.province_id
      );
      if (clearDistricts) {
        this.form.district_id = '';
      }
    },
    onGetApiPeru() {
      const params = {
        type: this.form.identity_document_type_id,
        number: this.form.document_number,
      };

      this.$axios.get('extras/apiperu', { params }).then((response) => {
        const { data } = response;
        const person = data.data;
        this.form.name = person.name;
        if (person.ubigeo) {
          [this.form.department_id, this.form.province_id, this.form.district_id] = person.ubigeo;
          this.form.address = person.address;
          this.onFilterProvinces();
          this.onFilterDistricts();
        }
      });
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
