import Vue from 'vue';
import VueRouter from 'vue-router';
import authPlugin from '../plugins/auth';
import auth from './auth';
import dashboard from './dashboard';

Vue.use(VueRouter);

const routes = [...auth, ...dashboard];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiredAuth = to.matched.some((route) => route.meta.requiredAuth);
  const isAuthenticated = authPlugin.check();
  if (to.name === 'VerifyEmail') {
    next();
  } else if (requiredAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (!requiredAuth && isAuthenticated) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;
