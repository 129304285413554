<template>
  <div class="auth__wrapper">
    <img
      src="@/assets/images/intranort-logo.png"
      alt="Logo intranort"
      class="img-fluid logo"
    />
    <div class="auth__wrapper-left">
      <img
        src="@/assets/images/login.svg"
        alt="Fonde del login"
        class="img-fluid"
      />
    </div>
    <div class="auth__wrapper-right">
      <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>
