<template>
  <ValidationObserver v-slot="{ invalid }" ref="formChangePassword">
    <form @submit.prevent="onSubmit">
      <el-dialog
        title="Cambiar mi contraseña"
        :visible="showModalChangePassword"
        width="500px"
        :before-close="onClose"
        @open="onOpenModal"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <ValidationProvider
          name="contraseña"
          vid="password"
          rules="required|min:6|confirmed:password_confirmation"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <label for="password">Contraseña nueva</label>
          <el-input
            type="password"
            id="password"
            v-model="form.password"
          ></el-input>
          <span class="el-form-item__error-visible el-form-item__error">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="confirmar contraseña"
          vid="password_confirmation"
          rules="required|min:6"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <label for="password_confirmation">Cofirmar contraseña nueva</label>
          <el-input
            type="password"
            id="password_confirmation"
            v-model="form.password_confirmation"
          ></el-input>
          <span class="el-form-item__error-visible el-form-item__error">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onClose" :disabled="loading">Cancelar</el-button>
          <el-button
            :disabled="invalid"
            :loading="loading"
            type="primary"
            native-type="submit"
            >Guardar</el-button
          >
        </div>
      </el-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { successTitle } from '@/plugins/messages';
import { mapGetters } from 'vuex';

export default {
  name: 'ChangePasswordComponent',
  data: () => ({
    form: {
      password: '',
      password_confirmation: '',
    },
  }),
  computed: {
    ...mapGetters(['loading', 'showModalChangePassword']),
  },
  methods: {
    onInitForm() {
      this.form.password = '';
      this.form.password_confirmation = '';
    },
    onSubmit() {
      this.$axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL_BASE}user/change-password`,
        data: this.form,
      })
        .then((response) => {
          this.$notify({
            title: successTitle,
            message: response.data.message,
            type: 'success',
          });
          this.onClose();
        })
        .catch((error) => this.showErrors(error, 'formChangePassword'));
    },
    onClose() {
      this.$store.dispatch('ON_HIDE_MODAL_CHANGE_PASSWORD');
    },
    onOpenModal() {
      this.onInitForm();
    },
  },
};
</script>
