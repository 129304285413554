<template>
  <div>
    <div v-if="isLoaderActive" class="loader-overlay">
      <img src="@/assets/images/intranort-logo.png" alt="Loader" class="loader-logo" />
      <div class="loader"></div>
    </div>
    <div :class="{ 'blur-content': isLoaderActive }">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RootComponent',
  mounted() {
    this.onFetchUser();
  },
  computed: {
    ...mapGetters(['isLoaderActive']),
  },
  methods: {
    onFetchUser() {},
  },
};
</script>

<style>
.loader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 188px;
  transform: translate(-50%, 150%);
  object-fit: contain;
  z-index: 1;
}
.blur-content {
  filter: blur(5px);
  transition: filter 0.3s ease;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* stylelint-disable */
.loader {
  --d: 50px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: #ff0000;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    /* stylelint-disable-line */ calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    /* stylelint-disable-line */ calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}
@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
